<template>
  <div class="toolbox" :class="toolbox ? 'toolbox--open' : 'toolbox--close'">
    <div class="toolbox-wrap" v-show="toolbox">
      <li @mouseenter="advisoryBox = true" @mouseleave="advisoryBox = false">
        <em class="iconfont icon-customservice"></em>
        <span>客服</span>
        <div class="item" v-show="advisoryBox">
          <el-image :src="config.toolbox.advisory.qrcode"></el-image>
          <p>{{ config.toolbox.advisory.text }}</p>
        </div>
      </li>
      <li class="officialBox" @mouseenter="officialBox = true" @mouseleave="officialBox = false">
        <em class="iconfont icon-custom-wechat"></em>
        <span>公众号</span>
        <div class="item" v-show="officialBox">
          <el-image :src="config.toolbox.official.qrcode"></el-image>
          <p>{{ config.toolbox.official.text }}</p>
        </div>
      </li>
      <li class="phoneNum" @mouseenter="phoneBox = true" @mouseleave="phoneBox = false">
        <em class="iconfont icon-telephone"></em>
        <span>电话</span>
        <div class="item" v-show="phoneBox">
          <p>{{ config.toolbox.telephone.text }}</p>
          <p>{{ config.toolbox.telephone.phone }}</p>
        </div>
      </li>
    </div>
    <div @click="toolbox = !toolbox" class="toolbox__feature" :class="toolbox ? 'toolbox__feature--open' : 'toolbox__feature--close'">
      <em :class="toolbox ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></em>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from 'vue'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

declare let globalConfig:any

export default defineComponent({
  setup () {
    // 使用路由
    const store = useStore()
    // const router = useRouter()
    const state = reactive({
      config: globalConfig,
      advisoryBox: false,
      phoneBox: false,
      officialBox: false,
      toolbox: false
    })
    const stateAsRefs = toRefs(state)
    const hasLogin = computed<boolean>(() => {
      return store.getters.GET_LOGIN
    })
    return {
      hasLogin,
      ...stateAsRefs
    }
  }
})
</script>

<style lang="scss" scoped>
  .toolbox {
    width: 64px;
    position: fixed;
    bottom: 12%;
    right: 15px;
    z-index: 9998;
    &--close {
      transition: all cubic-bezier(.52, 0, .46, 1) 0.8s;
      width: 48px;
    }
    &--open {
      transition: all 0.6s;
    }
    &__feature {
      width: 64px;
      cursor: pointer;
      color: #409EFF;
      background: #FFF;
      box-shadow: 0px 6px 15px 0px rgb(27 77 203 / 10%);
      margin-bottom: 12px;
      text-align: center;
      &--close {
        width: 48px;
        font-size: 16px;
        height: 48px;
        line-height: 48px;
        box-shadow: 0px 6px 15px 0px rgb(27 77 203 / 20%);
        border-radius: 100%;
      }
      &--open {
        padding: 2px 0;
        border-radius: 5px;
      }
    }
    li {
      width: 64px;
      height: 64px;
      background: #FFF;
      box-shadow: 0px 6px 15px 0px rgb(27 77 203 / 10%);
      border-radius: 5px;
      margin-bottom: 12px;
      padding-top: 8px;
      position: relative;
      list-style: none;
      cursor: pointer;
      color: #409EFF;
      .item {
        overflow: hidden;
        position: absolute;
        width: 144px;
        background: #FFF;
        box-shadow: 0px 6px 15px 0px rgb(27 77 203 / 10%);
        border-radius: 6px;
        top: 0;
        left: -154px;
        padding: 0 0 10px;
        img {
          max-width: 100%;
          max-height: 100%;
          border: none;
          vertical-align: middle;
        }
        p {
          color: #303133;
          text-align: center;
          font-size: 16px;
        }
      }
      &.phoneNum {
        .item {
          padding: 14px 0;
          p:first-child {
            margin-bottom: 3px;
          }
        }
      }
      &:hover {
        background: #409EFF;
        color: #FFF;
      }
      em {
        font-size: 36px;
        width: 36px;
        height: 36px;
        display: block;
        margin: 0 auto;
      }
      span {
        font-size: 14px;
        text-align: center;
        display: block;
        line-height: 1.8;
      }
    }
  }
</style>
