
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import Header from './components/Header.vue'
import ToolBox from './components/ToolBox.vue'
import Footer from './components/Footer.vue'
import { ElMessage } from 'element-plus'
import { IResponse } from '@/common/http'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

interface IDialogItem {
  short: string;
  qrcode: string;
}

interface IState {
  shortUrl: string;
  checked: boolean;
  shortDialog: boolean;
  config: any;
  dialogItem: IDialogItem;
}

declare let globalConfig:any

export default defineComponent({
  name: 'Index',
  components: {
    Header,
    ToolBox,
    Footer
  },
  setup () {
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      shortUrl: '',
      checked: false,
      shortDialog: false,
      config: globalConfig,
      dialogItem: {
        short: '',
        qrcode: ''
      }
    })
    const stateAsRefs = toRefs<IState>(state)
    const router = useRouter()
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)
    const navTo = (name: string): void => {
      router.push({
        name: name
      })
    }
    // 缩短链接
    const submit = (): void => {
      if (!state.shortUrl) {
        ElMessage.warning({
          message: '请输入短链接',
          type: 'warning'
        })
        return
      }
      if (!state.checked) {
        ElMessage.warning({
          message: '请阅读《链接内容管理》',
          type: 'warning'
        })
        return
      }
      proxy.$axios
        .post('/link/anygenerate', {
          link: state.shortUrl,
          checked: state.checked
        })
        .then((res: IResponse) => {
          if (res.code === 1) {
            // ElMessage.success({
            //   message: res.msg,
            //   type: 'success'
            // })
            state.dialogItem = res.data
            setTimeout(() => {
              state.shortDialog = true
            }, 100)
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
    }

    const copy = (): void => {
      const input = document.createElement('input')
      input.value = state.dialogItem.short
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      ElMessage.success({
        message: '已复制到粘贴板',
        type: 'success'
      })
    }

    const closeShortDialog = (): void => {
      state.shortUrl = ''
    }

    return {
      ...stateAsRefs,
      closeShortDialog,
      responsively,
      submit,
      copy,
      navTo
    }
  }
})
