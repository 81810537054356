<template>
  <div class="footer">
    <footer>
      <el-row>
        <el-col :span="24">
          <el-row>
            <el-col class="telephone tr" :xs="24" :sm="{ span: 8, offset: 16 }">
              <el-icon class="el-icon-phone-outline"></el-icon><a :href="`tel:${ config.telephone }`">{{ config.telephone }}</a>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="{ span: 16 }">
              <div class="contant">
                <div class="copyright">
                  © 2021  {{ config.name }} 版权所有
                </div>
                <!-- <div class="link">
                  <a href="/page/about">关于{{ config.abbreviation }}</a>
                </div> -->
                <div class="link">
                  <a href="/page/eula">用户协议</a>
                </div>
                <div class="link">
                  <a href="/page/privacy">隐私政策</a>
                </div>
                <div class="link">
                  <a href="/page/rules">链接内容管理</a>
                </div>
              </div>
            </el-col>
            <el-col class="icp tr" :xs="24" :sm="{ span: 8 }">
              <a href="https://beian.miit.gov.cn/">{{ config.icp }}</a>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </footer>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

declare let globalConfig:any

export default defineComponent({
  setup () {
    const config = globalConfig
    return {
      config
    }
  }
})
</script>

<style lang="scss" scoped>
  .footer {
    padding: 40px 0;
    color: #CCC;
    min-height: 60px;
    font-size: 16px;
    background: #252525;
    footer {
      margin: 0 auto;
      max-width: 1200px;
      padding: 0 20px;
    }
    .tr {
      text-align: right;
    }
    .tc {
      text-align: center;
    }
    .telephone {
      font-size: 26px;
      margin-bottom: 30px;
      i {
        margin-right: 8px;
      }
      a {
        color: #409EFF;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .contant{
      display: flex;
      flex-wrap: wrap;
      >.link >a{
        margin: 0 10px;
      }
    }
    @media only screen and (max-width: 768px) {
      .telephone {
        text-align: center;
      }
      .contant{
        text-align: center;
        line-height: 2;
        flex-direction: column;
      }
      .icp {
        text-align: center;
        line-height: 2;
      }
    }
  }
</style>
