<template>
  <div class="Header">
    <nav class="pc" aria-label="pc">
      <router-link class="logo" to="/">
        <el-image :src="config.logo"></el-image>
      </router-link>
      <ul class="nav-list">
        <li v-if="!hasLogin">
          <a :href="config.demo">DEMO报表</a>
        </li>
        <li @click="navTo('Login')" v-if="!hasLogin">登录</li>
        <div v-if="hasLogin" class="dropdown">
          <el-dropdown placement="bottom">
            <span class="avatar-dropdown el-dropdown-link">
              <li><el-avatar :src="userInfo.avatar"></el-avatar></li>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="navTo('Login')">进入首页</el-dropdown-item>
                <el-dropdown-item @click="exit" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </ul>
    </nav>
    <nav class="mobile" aria-label="mobile">
      <router-link style="padding: 0 20px;" class="logo" to="/">
        <el-image :src="config.logo"></el-image>
      </router-link>
      <ul class="nav-list">
        <li v-if="!hasLogin">
          <a :href="config.demo">DEMO报表</a>
        </li>
        <li @click="navTo('Login')" v-if="!hasLogin">登录</li>
        <div v-if="hasLogin" class="dropdown">
          <el-dropdown>
            <span class="avatar-dropdown el-dropdown-link">
              <li style="margin: 0 32px;">
                <el-avatar :src="userInfo.avatar"></el-avatar>
              </li>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="navTo('Login')">进入首页</el-dropdown-item>
                <el-dropdown-item @click="exit" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </ul>
    </nav>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

declare let globalConfig:any

export default defineComponent({
  setup () {
    // 使用路由
    const store = useStore()
    const router = useRouter()
    const { proxy } :any = getCurrentInstance()
    const state = reactive({
      config: globalConfig
    })
    const hasLogin = computed<boolean>(() => {
      return store.getters.GET_LOGIN
    })
    const userInfo = computed(() => {
      return store.getters.GET_USER_INFO
    })
    const navTo = (name: string): void => {
      if (!hasLogin.value) {
        router.push({
          name: name
        })
      } else {
        router.push({
          name: 'Shorten'
        })
      }
    }
    const exit = (): void => {
      proxy.$nextTick(() => {
        store.commit('Logout')
      })
    }
    const stateAsRefs = toRefs(state)
    return {
      navTo,
      hasLogin,
      userInfo,
      exit,
      ...stateAsRefs
    }
  }
})
</script>
<style lang="scss" scoped>
  .Header {
    width: 100%;
    background: #FFF;
    border-bottom: 1px solid #EBEEF5;
    box-shadow: 0 1px 2px rgba(16,16,17,.02), 0 3.4px 8px rgba(16,16,17,.007), 0 12px 30px rgba(16,16,17,.003);
    .logo {
      color: #161617;
      padding: 0 40px;
      font-weight: 100;
      font-size: 20px;
      cursor: pointer;
      width: 100px;
      :deep(.el-image) {
        vertical-align: middle;
      }
    }
    .nav-list {
      display: flex;
      li {
        font-size: 14px;
        cursor: pointer;
        color: #909399;
        &:hover {
          color: #303133;
        }
      }
      .dropdown {
        height: 40px;
        overflow: hidden;
      }
    }
  }
  // 响应式
  .Header {
    @mixin nav {
      max-width: 1200px;
      width: 100%;
      height: 60px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
    }
    .pc {
      display: flex;
      @include nav;
      .nav-list li{
        margin: 0 40px;
      }
    }
    .mobile {
      display: none;
      @include nav;
      .nav-list li{
        margin-right: 20px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .Header {
      .pc {
        display: none;
      }
      .mobile {
        display: flex;
      }
    }
  }
</style>
