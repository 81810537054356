<template>
  <div class="Index">
    <Header />
    <div class="main">
      <div class="main-intro">
        <h1>从链接开始</h1>
        <h2>简约的短链接工具</h2>
      </div>
      <div class="main-wrapper">
        <el-input
          v-model="shortUrl"
          placeholder="输入需要缩短的链接"
        ></el-input>
        <el-button type="primary" @click="submit"> 立即缩短 </el-button>
      </div>
      <div class="protocol">
        <el-checkbox v-model="checked">
          <div>
            我同意并遵守
            <a
              target="_blank"
              href="/page/rules"
              @click.stop
            >
              《链接内容管理》
            </a>
          </div>
        </el-checkbox>
      </div>
    </div>
    <!-- <div class="intro">
      <el-row class="intro__row">
        <el-col :xs="24" :span="8">
          <el-card class="intro-card">
            <div class="intro-card__header">
              <el-icon class="intro-card__icon el-icon-link"></el-icon>
              <h4>链接缩短</h4>
            </div>
            <div class="intro-card__description">
              简单易用, 多个域名可选, https协议为您安全护航, 支持API调用缩短,
              跳转快速稳定
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :span="8" style="padding: 0 24px">
          <el-card class="intro-card">
            <div class="intro-card__header">
              <el-icon class="intro-card__icon el-icon-folder-opened"></el-icon>
              <h4>扩展功能</h4>
            </div>
            <div class="intro-card__description">
              为短网址提供多样功能，链接备注、有效日期、原链接可编辑、访问密码等。
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :span="8">
          <el-card class="intro-card">
            <div class="intro-card__header">
              <el-icon class="intro-card__icon el-icon-pie-chart"></el-icon>
              <h4>数据分析</h4>
            </div>
            <div class="intro-card__description">
              分析用户属性，设备，浏览器，分辨率，到所在省市、IP地址，手机号，统统收集并实时绘制报表
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div> -->
    <div class="advantage clearfix">
      <div class="content">
        <p class="title">{{ config.abbreviation }}多年技术积累和优势</p>
        <div class="advantage-box">
          <div class="advantage-msg">
            <div class="advantage-img">
              <img
                src="@/assets/images/index/money.svg"
              />
            </div>
            <div class="advantage-des">
              <div class="advantage-des-title">质优价低</div>
              <div class="advantage-des-msg">
                <ul>
                  <li>高性价比，省钱又省心</li>
                  <li>计费灵活，多种可选套餐</li>
                  <li>智能过滤虚假流量，节约成本</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="advantage-msg">
            <div class="advantage-img">
              <img
                src="@/assets/images/index/api.svg"
              />
            </div>
            <div class="advantage-des">
              <div class="advantage-des-title">API简单易用</div>
              <div class="advantage-des-msg">
                <ul>
                  <li>稳定高并发</li>
                  <li>二次开发接入简便</li>
                  <li>提供多语言示例代码</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="advantage-msg">
            <div class="advantage-img">
              <img
                src="@/assets/images/index/chart.svg"
              />
            </div>
            <div class="advantage-des">
              <div class="advantage-des-title">专业数据分析</div>
              <div class="advantage-des-msg">
                <ul>
                  <li>低成本接入，无需埋点</li>
                  <li>高级报表-全面展示网址点击数据</li>
                  <li>分析云-精准锁定目标人群</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="advantage-msg">
            <div class="advantage-img">
              <img
                src="@/assets/images/index/security.svg"
              />
            </div>
            <div class="advantage-des">
              <div class="advantage-des-title">安全高可用</div>
              <div class="advantage-des-msg">
                <ul>
                  <li>稳定性（SLA）99.9%</li>
                  <li>防劫持，无恶意广告</li>
                  <li>1V1专业技术支持</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="application clearfix">
      <div class="content">
        <p class="title">满足多种应用场景</p>
        <p class="title-des">
          提供专业、全面的数据分析，帮助您更了解您的用户，挖掘潜在用户，提高转化。
        </p>
        <div class="application-msg">
          <div class="application-img">
            <img
              src="@/assets/images/index/icon1.png"
              style="width: 100%"
            />
          </div>
          <div class="application-msg-des">
            <div class="application-msg-des-top">
              <p class="application-des-title">短信营销</p>
              <p class="application-des-title-des">
                营销短信结合个性化短网址，根据短网址点击情况提高转化率。
              </p>
            </div>
            <div class="application-msg-des-bottom">
              <div class="application-des-img">
                <img
                  src="/images/index/pic1.jpg"
                  style="height: 90%"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="application-msg">
          <div class="application-img">
            <img
              src="@/assets/images/index/icon2.png"
              style="width: 100%"
            />
          </div>
          <div class="application-msg-des">
            <div class="application-msg-des-top">
              <p class="application-des-title">APP分享</p>
              <p class="application-des-title-des">
                电商、社交、工具等各类app进行社会化分享时可使用短网址接口，缩短链接便于分享传播。
              </p>
            </div>
            <div class="application-msg-des-bottom">
              <div class="application-des-img">
                <img
                  src="/images/index/pic2.png"
                  style="height: 90%"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="application-msg">
          <div class="application-img">
            <img
              src="@/assets/images/index/icon3.png"
              style="width: 100%"
            />
          </div>
          <div class="application-msg-des">
            <div class="application-msg-des-top">
              <p class="application-des-title">二维码简化</p>
              <p class="application-des-title-des">
                降低二维码复杂程度，减少二维码像素，提升识别速度及成功率。
              </p>
            </div>
            <div class="application-msg-des-bottom">
              <div class="application-des-img">
                <img
                  src="/images/index/pic3.png"
                  class="qrcode"
                  style="height: 80%"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="application-msg">
          <div class="application-img">
            <img
              src="@/assets/images/index/icon4.png"
              style="width: 100%"
            />
          </div>
          <div class="application-msg-des">
            <div class="application-msg-des-top">
              <p class="application-des-title">渠道分析/AB测试</p>
              <p class="application-des-title-des">
                营销渠道分析/AB测试分析各大渠道，协助推广决策，优化成本及效率。
              </p>
            </div>
            <div class="application-msg-des-bottom">
              <div class="application-des-img">
                <img
                  src="/images/index/pic4.png"
                  style="height: 90%; width: 90%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fun">
      <p class="title">专业的数据统计分析报表</p>
      <p class="title-des">
        多维度统计短网址点击数据，为精细化运营决策提供数据支持，进而有效提高企业投资回报率
      </p>
      <div class="fun-msg">
        <div class="fun-msg-wrap">
          <div class="fun-msg-des">
            <div class="function-des-msg-img">
              <img
                src="@/assets/images/index/icon5.png"
                style="width: 80%"
              />
            </div>
            <div class="function-des-msg-title">低成本接入</div>
            <div class="function-des-msg-des">
              <span
                >低成本接入，与{{ config.abbreviation }}平台无缝对接，无需单独埋点。辅以完备的指导说明，践行简单易用的理念。</span
              >
            </div>
          </div>
          <div class="fun-msg-des">
            <div class="function-des-msg-img">
              <img
                src="@/assets/images/index/icon6.png"
                style="width: 80%"
              />
            </div>
            <div class="function-des-msg-title">智能过滤虚假流量</div>
            <div class="function-des-msg-des">
              <span
                >智能识别技术，能有效识别流量真实性，过滤虚假流量，避免虚假流量产生不必要的费用，不仅帮助您节约成本，同时保证统计数据更精准。</span
              >
            </div>
          </div>
          <div class="fun-msg-des">
            <div class="function-des-msg-img">
              <img
                src="@/assets/images/index/icon7.png"
                style="width: 80%"
              />
            </div>
            <div class="function-des-msg-title">权威数据分析</div>
            <div class="function-des-msg-des">
              <span
                >{{ config.abbreviation }}作为专业的中文网站流量分析平台，无缝结合短网址，提供权威、全面的网址点击数据。帮助您更了解用户，提高转化率。</span
              >
            </div>
          </div>
        </div>
        <div class="fun-img">
          <img
            src="/images/index-charts.png"
            style="width: 100%"
          />
        </div>
      </div>
    </div>
    <div class="experience">
      <p class="title">开启您的短网址之旅</p>
      <div class="operation-btn">
        <el-button type="primary" class="operation-btn" @click="navTo('Login')">
          立即体验
        </el-button>
      </div>
    </div>
    <div class="friendship">
      <p>友情链接</p>
      <div class="links" :style="{justifyContent:(config.friendLinks.length > 5 ? 'center' : 'left')}">
        <template v-for="(item, index) in config.friendLinks" :key="index">
          <a :href="item.href" target="_blank" :title="item.title">{{item.title}}</a>
        </template>
      </div>
    </div>
    <Footer />
    <!-- Dialog弹出层 -->
    <!-- 生成后对话框 -->
    <teleport to="body">
      <div class="short-dialog">
        <el-dialog
          center
          custom-class="short-dialog"
          top="30vh"
          :width="responsively ? 280 : 480"
          destroy-on-close
          title="生成信息"
          :close-on-click-modal="false"
          @close="closeShortDialog"
          v-model="shortDialog"
        >
          <el-image :src="dialogItem.qrcode"></el-image>
          <p>
            {{ dialogItem.short
            }}<em @click="copy" class="icon el-icon-copy-document"></em>
          </p>
        </el-dialog>
      </div>
    </teleport>
  </div>
  <teleport to='body'>
    <tool-box></tool-box>
  </teleport>
</template>
<script lang="ts">
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import Header from './components/Header.vue'
import ToolBox from './components/ToolBox.vue'
import Footer from './components/Footer.vue'
import { ElMessage } from 'element-plus'
import { IResponse } from '@/common/http'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

interface IDialogItem {
  short: string;
  qrcode: string;
}

interface IState {
  shortUrl: string;
  checked: boolean;
  shortDialog: boolean;
  config: any;
  dialogItem: IDialogItem;
}

declare let globalConfig:any

export default defineComponent({
  name: 'Index',
  components: {
    Header,
    ToolBox,
    Footer
  },
  setup () {
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      shortUrl: '',
      checked: false,
      shortDialog: false,
      config: globalConfig,
      dialogItem: {
        short: '',
        qrcode: ''
      }
    })
    const stateAsRefs = toRefs<IState>(state)
    const router = useRouter()
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)
    const navTo = (name: string): void => {
      router.push({
        name: name
      })
    }
    // 缩短链接
    const submit = (): void => {
      if (!state.shortUrl) {
        ElMessage.warning({
          message: '请输入短链接',
          type: 'warning'
        })
        return
      }
      if (!state.checked) {
        ElMessage.warning({
          message: '请阅读《链接内容管理》',
          type: 'warning'
        })
        return
      }
      proxy.$axios
        .post('/link/anygenerate', {
          link: state.shortUrl,
          checked: state.checked
        })
        .then((res: IResponse) => {
          if (res.code === 1) {
            // ElMessage.success({
            //   message: res.msg,
            //   type: 'success'
            // })
            state.dialogItem = res.data
            setTimeout(() => {
              state.shortDialog = true
            }, 100)
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
    }

    const copy = (): void => {
      const input = document.createElement('input')
      input.value = state.dialogItem.short
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      ElMessage.success({
        message: '已复制到粘贴板',
        type: 'success'
      })
    }

    const closeShortDialog = (): void => {
      state.shortUrl = ''
    }

    return {
      ...stateAsRefs,
      closeShortDialog,
      responsively,
      submit,
      copy,
      navTo
    }
  }
})
</script>
<style lang="scss" scoped>
.clearfix:after {
  content: "\20";
  display: block;
  height: 0;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.Index {
  background: #fff;
  .protocol {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    div {
      color: #FFF;
    }
  }
  .main {
    padding: 120px 0;
    position: relative;
    z-index: 998;
    background: url("/images/index_bg.jpg") center center / cover no-repeat;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #000;
      opacity: 0.7;
      z-index: -1;
    }
    &-intro {
      text-align: center;
      color: #fff;
      h1 {
        line-height: 1.6;
      }
      h2 {
        margin: 12px 0 24px;
      }
    }
    &-wrapper {
      display: flex;
      width: 96%;
      max-width: 700px;
      margin: 0 auto;
      justify-content: space-between;
    }
    .el-input {
      width: 580px;
    }
    :deep(.el-input__inner) {
      height: 46px;
      border-radius: 0;
    }
    .el-button {
      border-radius: 0;
    }
  }
  .banner {
    width: 100%;
    .el-image {
      width: 600px;
      padding: 40px 0;
      display: block;
      margin: 0 auto;
    }
  }
  .intro {
    background: #eee;
    &__row {
      max-width: 1000px;
      margin: 0 auto;
    }
    &-card {
      :deep(.el-card__body) {
        min-height: 150px;
      }
      &__icon {
        font-size: 32px;
        color: #409eff;
      }
      max-width: 600px;
      width: 100%;
      margin: 20px auto;
      &__header {
        display: flex;
        align-items: center;
        h4 {
          margin-left: 8px;
        }
      }
      &__description {
        margin: 12px 0;
        line-height: 2;
      }
    }
  }
  .advantage {
    width: 100%;
    box-sizing: border-box;
    background-color: #f8fcff;
    &-box {
      clear: both;
      box-sizing: border-box;
      width: 100%;
    }
    &-msg {
      box-sizing: border-box;
      float: left;
      @media screen and (max-width: 1080px) {
        width: 50%;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      width: 25%;
      height: 400px;
    }
    &-img {
      box-sizing: border-box;
      height: 150px;
      position: relative;
      img {
        position: relative;
        top: 50%;
        left: 50%;
        width: 80px;
        margin-left: -40px;
        margin-top: -40px;
      }
    }
    &-des {
      box-sizing: border-box;
      height: 200px;
      &-title {
        box-sizing: border-box;
        @media screen and (max-width: 960px) {
          font-size: 18px;
        }
        color: #666;
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        padding: 20px 0;
      }
      &-msg {
        box-sizing: border-box;
        text-align: center;
        ul {
          box-sizing: border-box;
          padding: 0;
          li {
            box-sizing: border-box;
            // @media screen and (max-width: 960px) {
            //   font-size: 12px;
            // }
            font-size: 14px;
            list-style: none;
            padding: 10px 0;
          }
        }
      }
    }
  }
  .application {
    width: 100%;
    box-sizing: border-box;
    &-des-img {
      text-align: center;
      height: 100%;
      padding-top: 20px;
      box-sizing: border-box;
      img {
        vertical-align: middle;
        border-style: none;
        height: 90%;
        box-sizing: border-box;
        @media screen and (max-width: 960px) {
          width: 90%;
        }
      }
    }
    &-msg {
      @media screen and (min-width: 768px) {
        &:nth-child(2n) {
          margin-left: 4%;
        }
      }
      box-sizing: border-box;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      padding: 10px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      width: 48%;
      height: 300px;
      display: inline-block;
      margin-bottom: 50px;
      &-des {
        box-sizing: border-box;
        float: right;
        width: 80%;
        height: 100%;
        &-top {
          @media screen and (max-width: 768px) {
            height: 35%;
          }
          box-sizing: border-box;
          width: 100%;
          height: 30%;
        }
        &-bottom {
          box-sizing: border-box;
          width: 100%;
          height: 70%;
        }
        &-title-des {
          box-sizing: border-box;
          height: 15%;
          @media screen and (max-width: 960px) {
            font-size: 12px;
          }
        }
      }
    }
    &-img {
      width: 17%;
      height: 100%;
      display: inline-block;
      vertical-align: top;
      img {
        width: 100%;
      }
    }
  }
  .fun {
    width: 100%;
    box-sizing: border-box;
    background-color: #f8fcff;
    @media screen and (max-width: 768px) {
      padding: 20px 20px;
    }
    &-msg {
      height: 25%;
      @media screen and (min-width: 768px) {
        padding: 20px 50px;
      }
      position: relative;
      &-wrap {
        width: 48%;
        display: inline-block;
        flex-direction: column;
        @media screen and (max-width: 768px) {
          width: 100%;
          display: block;
        }
      }
      &-des {
        width: 100%;
        flex: 1;
        margin: 50px 0;
        display: flex;
        .function-des-msg {
          &-img {
            position: relative;
            flex: 2;
            text-align: center;
            img {
              width: 80%;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
              vertical-align: middle;
              border-style: none;
            }
          }
          &-title {
            flex: 4;
            vertical-align: top;
            font-size: 20px;
            padding-left: 20px;
            display: flex;
            align-items: center;
          }
          &-des {
            flex: 8;
            vertical-align: top;
            font-size: 20px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
    &-img {
      @media screen and (max-width: 768px) {
        width: 100%;
        display: block;
      }
      @media screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 30px;
      }
      width: 46%;
      display: inline-block;
      vertical-align: top;
      box-shadow: 0 2px 4px rgb(85 159 243 / 36%), 0 0 6px rgb(85 159 243 / 36%);
      img {
        width: 100%;
        vertical-align: middle;
        border-style: none;
      }
    }
  }
  .experience {
    background-color: #415079;
    color: #fff;
    width: 100%;
    height: 280px;
    .operation-btn {
      margin: 40px auto;
      width: 148px;
      height: 38px;
      border-radius: 0;
    }
  }
  .extension {
    background-color: #0f1013;
    color: #fff;
    height: 400px;
    width: 100%;
    text-align: center;
    &-box {
      display: flex;
    }
    &-msg {
      padding: 30px 0;
      flex: 1;
      img {
        width: 80%;
        @media screen and (min-width: 768px) {
          width: 30%;
        }
      }
      .jump {
        font-size: 14px;
        line-height: 1.5;
        margin-top: 30px;
      }
    }
  }
  .friendship {
    background-color: #FFFFFF;
    color: #000;
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
    overflow: hidden;
    .links {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 12px 0;
      a {
        padding: 0 8px;
        &:hover {
          color: #666;
          transition: color .4s;
        }
      }
    }
  }
  .content {
    @media screen and (max-width: 768px) {
      padding: 0 6%;
    }
    padding: 0 12%;
    box-sizing: border-box;
  }
  .title {
    box-sizing: border-box;
    @media screen and (max-width: 960px) {
      font-size: 24px;
    }
    font-size: 30px;
    text-align: center;
    margin-bottom: 1em;
    padding-top: 50px;
  }
  .title-des {
    text-align: center;
    margin-bottom: 50px;
  }
}

.short-dialog {
  .icon {
    cursor: pointer;
    margin-left: 4px;
  }
  :deep(.el-dialog__body) {
    text-align: center;
  }
}
</style>
