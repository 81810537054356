<template>
  <router-view />
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { defineComponent, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { IResponse } from './common/http'
export default defineComponent({
  name: 'App',
  setup () {
    const store = useStore()
    const router = useRouter()
    const { proxy }: any = getCurrentInstance()
    const resize = (): void => {
      window.onresize = () => {
        resizeWidth()
      }
    }
    const resizeWidth = (): void => {
      const clientWidth = document.body.clientWidth
      const menubar = store.getters.GET_MENU_BAR
      if (clientWidth <= 768) {
        store.commit('SET_RESPONSIVELY_MODE', true)
        if (menubar) {
          store.commit('SET_MENU_BAR', false)
        }
      } else {
        store.commit('SET_RESPONSIVELY_MODE', false)
        if (menubar) {
          store.commit('SET_MENU_BAR', true)
        }
      }
    }

    const checkLogin = (): void => {
      const token = localStorage.getItem('s')
      const userInfo = localStorage.getItem('us')
      if (userInfo) {
        store.commit('SET_USER_INFO', JSON.parse(userInfo))
      }
      if (token) {
        proxy.$axios.post('/user/info', {}, {
          headers: {
            token
          }
        }).then((res: IResponse) => {
          if (res.code === 1) {
            store.commit('Login', res.data)
            proxy.$axios.defaults.headers.token = res.data.token
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
            setTimeout(() => {
              store.commit('Logout')
              router.push({ name: 'Login' })
            }, 100)
          }
        })
      }
    }
    resizeWidth()
    resize()
    checkLogin()
  }
})
</script>

<style lang="scss">
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none;
    &:link, &:visited {
      color: inherit;
    }
  }
  .el-drawer__body {
    background-color: rgb(21, 23, 32);
    a {
      color: #FFF;
      display: block;
      margin: 0 auto;
      padding: 20px 30px;
      &:hover {
        color: #ffd04b;
        background: #333;
      }
    }
    i {
      margin-right: 12px;
    }
  }
</style>
