
import { defineComponent } from 'vue'

declare let globalConfig:any

export default defineComponent({
  setup () {
    const config = globalConfig
    return {
      config
    }
  }
})
