
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

declare let globalConfig:any

export default defineComponent({
  setup () {
    // 使用路由
    const store = useStore()
    const router = useRouter()
    const { proxy } :any = getCurrentInstance()
    const state = reactive({
      config: globalConfig
    })
    const hasLogin = computed<boolean>(() => {
      return store.getters.GET_LOGIN
    })
    const userInfo = computed(() => {
      return store.getters.GET_USER_INFO
    })
    const navTo = (name: string): void => {
      if (!hasLogin.value) {
        router.push({
          name: name
        })
      } else {
        router.push({
          name: 'Shorten'
        })
      }
    }
    const exit = (): void => {
      proxy.$nextTick(() => {
        store.commit('Logout')
      })
    }
    const stateAsRefs = toRefs(state)
    return {
      navTo,
      hasLogin,
      userInfo,
      exit,
      ...stateAsRefs
    }
  }
})
