import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Index from '../views/Index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      title: '缩吧短网址-好用的短网址生成管理平台'
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: '登录—缩吧短网址'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: '注册账号—缩吧短网址'
    },
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    meta: {
      title: '忘记密码—缩吧短网址'
    },
    component: () => import('@/views/ForgetPw.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      title: '控制台—缩吧短网址'
    },
    component: () => import('@/views/dashboard/Index.vue'),
    children: [
      {
        path: '',
        name: 'Shorten',
        component: () => import('@/views/dashboard/shorten/Shorten.vue')
      },
      {
        path: '/pages/link',
        name: 'Link',
        component: () => import('@/views/dashboard/link/Link.vue')
      },
      {
        path: '/pages/cost',
        name: 'Cost',
        component: () => import('@/views/dashboard/cost/Cost.vue')
      },
      {
        path: '/pages/real',
        name: 'Real',
        component: () => import('@/views/dashboard/realname/RealName.vue')
      },
      {
        path: '/pages/api',
        name: 'Api',
        component: () => import('@/views/dashboard/api/Api.vue')
      },
      {
        path: '/pages/news',
        name: 'News',
        component: () => import('@/views/dashboard/news/News.vue')
      }
    ]
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: {
      title: '支付中心—缩吧短网址'
    },
    component: () => import('@/views/dashboard/other/Pay.vue')
  },
  {
    path: '/data-analysis',
    name: 'DataAnalysis',
    meta: {
      title: '数据分析—缩吧短网址'
    },
    component: () => import('@/views/dashboard/other/DataAnalysis.vue')
  },
  {
    path: '/page/:alias',
    meta: {
      title: '缩吧短网址-好用的短网址生成管理平台'
    },
    component: () => import('@/views/pageMode.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      title: '哎呀，页面没找到呢'
    },
    component: () => import('@/views/Sorry.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = String(to.meta.title)
  }
  next()
})

export default router
