import { createStore } from 'vuex'

interface IState {
  hasLogin: boolean,
  token: string,
  userInfo: string,
  menuBar: boolean,
  responsively: boolean,
  widescreen: boolean,
  analysisUrl: string
}

export default createStore({
  // 严格模式
  strict: process.env.NODE_ENV !== 'production',
  state: {
    hasLogin: false, // 存放用户登录状态
    token: '', // 存放用户令牌
    userInfo: '', // 存放用户数据
    menuBar: false, // 用户中心侧边栏
    responsively: false, // 响应式 false为PC true为mobile
    widescreen: false, // 宽屏模式
    analysisUrl: 'http://192.168.31.60:8080' // 数据分析跳转链接
  },
  getters: {
    GET_LOGIN (state: IState) {
      return state.hasLogin
    },
    GET_TOKEN (state: IState) {
      return state.token
    },
    GET_USER_INFO (state: IState) {
      return state.userInfo
    },
    GET_MENU_BAR (state: IState) {
      return state.menuBar
    },
    GET_RESPONSIVELY_MODE (state: IState) {
      return state.responsively
    },
    GET_WIDE_SCREEN (state: IState) {
      return state.widescreen
    },
    GET_ANALYSIS_URL (state: IState) {
      return state.analysisUrl
    }
  },
  mutations: {
    Login (state: IState, payload) {
      state.hasLogin = true
      state.token = payload.token
      state.userInfo = payload
      localStorage.setItem('us', JSON.stringify(payload))
      localStorage.setItem('s', payload.token)
      if (process.env.NODE_ENV === 'development') {
        console.log(state.token)
        console.log(payload)
      }
    },
    Logout (state: IState) {
      state.hasLogin = false
      state.token = ''
      state.userInfo = ''
      localStorage.removeItem('us')
      localStorage.removeItem('s')
    },
    SET_USER_INFO (state: IState, payload) {
      localStorage.setItem('us', JSON.stringify(payload))
      if (process.env.NODE_ENV === 'development') {
        console.log(payload)
      }
      state.userInfo = payload
    },
    SET_MENU_BAR (state: IState, payload: boolean) {
      state.menuBar = payload
    },
    SET_RESPONSIVELY_MODE (state: IState, payload: boolean) {
      state.responsively = payload
    },
    SET_WIDE_SCREEN (state: IState, payload) {
      state.widescreen = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
