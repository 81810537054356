import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElLoading, ElMessage } from 'element-plus'

// 后端返回格式接口
export interface IResponse {
  code: number;
  data: null | any;
  msg: string;
  time: string;
}

// 加载配置接口
interface ILoadOptions {
  lock: boolean;
  text: string;
  background?: string;
}

// 自定义请求配置接口
interface ICustomConfig {
  baseUrl: string;
  timeout: number;
  timer: any;
  loading: any;
  loadingTime: number;
  showLoading: boolean;
  loadingOptions: ILoadOptions;
}

// 自定义请求配置参数
const customConfig: ICustomConfig = {
  baseUrl: 'https://api.suoba.cn',
  timeout: 10000,
  timer: null, // 计时器
  loading: null, // 载入器
  loadingTime: 800, // 超出此时间的请求会显示loading
  showLoading: true,
  loadingOptions: {
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0)'
  }
}

// 开始载入
const startLoading = (): void => {
  customConfig.loading = ElLoading.service(customConfig.loadingOptions)
}

// 结束载入
const endLoading = (): void => {
  clearTimeout(customConfig.timer)
  if (customConfig.loading) {
    customConfig.loading.close()
  }
}

// 自动装配自定义请求配置
axios.defaults.baseURL = customConfig.baseUrl
axios.defaults.timeout = customConfig.timeout

// 请求拦截
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(config)
  }
  if (localStorage.getItem('s')) {
    config.headers.token = localStorage.getItem('s')
  }
  if (customConfig.showLoading && !customConfig.timer) {
    customConfig.timer = setTimeout(() => {
      startLoading()
      customConfig.timer = null
    }, customConfig.loadingTime)
  }
  return config
})
// 响应拦截
axios.interceptors.response.use((response: AxiosResponse<any>) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(response)
  }
  // 结束loading
  endLoading()
  return response.data
}, error => {
  if (process.env.NODE_ENV === 'development') {
    console.error(error)
  }
  // 结束loading
  endLoading()
  ElMessage.error('请求失败')
  return Promise.reject(error)
})

export default axios
