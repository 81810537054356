
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { defineComponent, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { IResponse } from './common/http'
export default defineComponent({
  name: 'App',
  setup () {
    const store = useStore()
    const router = useRouter()
    const { proxy }: any = getCurrentInstance()
    const resize = (): void => {
      window.onresize = () => {
        resizeWidth()
      }
    }
    const resizeWidth = (): void => {
      const clientWidth = document.body.clientWidth
      const menubar = store.getters.GET_MENU_BAR
      if (clientWidth <= 768) {
        store.commit('SET_RESPONSIVELY_MODE', true)
        if (menubar) {
          store.commit('SET_MENU_BAR', false)
        }
      } else {
        store.commit('SET_RESPONSIVELY_MODE', false)
        if (menubar) {
          store.commit('SET_MENU_BAR', true)
        }
      }
    }

    const checkLogin = (): void => {
      const token = localStorage.getItem('s')
      const userInfo = localStorage.getItem('us')
      if (userInfo) {
        store.commit('SET_USER_INFO', JSON.parse(userInfo))
      }
      if (token) {
        proxy.$axios.post('/user/info', {}, {
          headers: {
            token
          }
        }).then((res: IResponse) => {
          if (res.code === 1) {
            store.commit('Login', res.data)
            proxy.$axios.defaults.headers.token = res.data.token
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
            setTimeout(() => {
              store.commit('Logout')
              router.push({ name: 'Login' })
            }, 100)
          }
        })
      }
    }
    resizeWidth()
    resize()
    checkLogin()
  }
})
