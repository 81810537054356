
import { computed, defineComponent, reactive, toRefs } from 'vue'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

declare let globalConfig:any

export default defineComponent({
  setup () {
    // 使用路由
    const store = useStore()
    // const router = useRouter()
    const state = reactive({
      config: globalConfig,
      advisoryBox: false,
      phoneBox: false,
      officialBox: false,
      toolbox: false
    })
    const stateAsRefs = toRefs(state)
    const hasLogin = computed<boolean>(() => {
      return store.getters.GET_LOGIN
    })
    return {
      hasLogin,
      ...stateAsRefs
    }
  }
})
